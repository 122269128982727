<template>
  <div class="news-container">
    <div class="news-content">
      <!-- 默认 最新 最热 -->
      <!-- <div class="news-content-pic">
        <img
          src="https://image.bookgo.com.cn/webculture/jm/news/news-headpic.png"
          alt=""
        />
      </div> -->

      <div class="tab-secondNav" v-if="secondNav.length>0">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}">

                </span>
            </span>
          </li>
        </ul>

      </div>

      <div class="tab-subSwitch" v-if="subTypeList.length>0">
        <!-- <div class="tab-subSwitch-title">分类：</div> -->
        <ul class="tab-subSwitch-choose-item">
          <li
            @click="changeTab(-1, -1)"
            :class="{ tabActive: cur2 == -1}"
          >
            全部
          </li>
          <li
            v-for="(item, i) in subTypeList"
            :key="i"
            :class="{ tabActive: cur2 == item.id, 'imgType':item.name.length > 4 }"
            @click="changeTab(item.id, i)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="tab-subSwitch" v-if="childTypeList.length>0">
        <!-- <div class="tab-subSwitch-title">分类：</div> -->
        <ul class="tab-subSwitch-choose-item">
          <li
              @click="changesenTab(-1, -1)"
              :class="{ tabActive: seccur == -1 }"
          >
            全部
          </li>
          <li
              v-for="(item, i) in childTypeList"
              :key="i"
              :class="{ tabActive: seccur == i }"
              @click="changesenTab(item.id, i)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div class="tab-data">共{{ totalPage }}页 | {{ total }}条数据</div>
      <common-list 
      :list="tabMain"
      :name="name"
      :navId="navId"
      />

      <!-- 分页组件 -->
      <Pagination
        :total="total"
        @pagination="getInfoList(1)"
        :page.sync="page"
        :limit.sync="limit"
        v-if="tabMain && tabMain.length !== 0"
      />
    </div>
  </div>
</template>

<script>
import { cateList, list } from "../../api/article";
import {navChildList} from '../../api/header/header'
import moment from "moment";
import Pagination from "@/components/Pagination/index.vue";
import commonList from "@/views/components/commonList.vue";
export default {
  name: "news",
  data() {
    return {
      current: 0,
      cur: -1,
      seccur:-1,
      cur2:-1,
      total: 0,
      aid: process.env.VUE_APP_AID,
      page: 1,
      havChild:0,
      navId:"",
      id: "",
      cat_id: "",
      limit: 10,
      listLoading: true,
      subTypeList: [],
      childTypeList: [],
      tabMain: [],
      secondNav:[],
      allChildCat:[],
      catID:0,
      name:''
    };
  },
  components: {
    Pagination,
    commonList,
  },
  mounted() {
    window.scroll(0, 0);
    this.id = this.$route.query.id;
    this.name = this.$route.query.name
    this.navId = this.$route.query.navId
    this.cat_id = this.$route.query.cat_id ? this.$route.query.cat_id : 0;
    this.catID = this.cat_id;
    // alert(this.navId)
    sessionStorage.setItem('secondSelectId',this.cat_id);

    this.getChildNav()
    // this.getCategory();
    // this.getInfoList(1);
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    $route() {
      this.cat_id = this.$route.query.cat_id;
      this.catID = this.cat_id;
      console.log(this.cat_id)
      this.getChildNav()
      // this.getInfoList(1)
    },
    cat_id(newV, oldV) {
      console.log(newV, oldV);
      this.cat_id = newV;
      if(newV!='undefined'){
        this.id = newV;

      }

      this.getCategory();
      //  alert('执行cat_id')
      // alert(newV)
      this.getInfoList(1);
    },
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      sessionStorage.setItem('secondSelectId',item.id)
      this.subTypeList = []
      this.childTypeList = []
      if(item.url.indexOf("navId")==-1){
        item.url +="&navId="+this.navId;
      }
      // return
        // alert(item.url)
        this.$router.push({
          path: item.url,
        });

    },
    getInfoList(val,fId) {
      // alert('s'+val)
      if(val == -1) {
        // alert(111)
        fId = false;
        val = 1;
        var val2 = -1
        var cat_ids = this.$route.query.id
        this.cur2 = -1
        console.log(this.cat_ids)

      }
      // alert( this.$route.query.cat_id)
      //获取第一个子分类id
      // if(val == 1) {
      //   this.getCategory('getID')
      //   this.cat_id = fId
      // }
      // console.log(this.cat_ids)
      if(fId){
        var cat_id = val;
      }else{
        var cat_id = this.cat_id == 0? this.id : this.cat_id;
      }
      // alert(val)
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        app_type: 1,
        search_sub_cat: (this.cat_id == 0 || val == 1) ? 1 : 0,
        cat_id: val2 == -1 ? cat_ids:cat_id,
      };
      console.log(params)

      this.listLoading = true;
      list(params).then((res) => {
        console.log(res)
        this.listLoading = false;
        this.tabMain = res.data.list;
        res.data.list.forEach((item) => {
          item.publish_time = moment(item.publish_time * 1000).format(
            "YYYY年MM月DD日"
          );
        });
        this.total = res.data.count;
      });
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.navId,
        app_type: 1,
      };

      navChildList(params).then((res) => {
        console.log(res)
        this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data
          var data = res.data;
          this.secondNav = data;
          var status = false
          data.forEach((item) => {
            if (item.name == this.$route.query.name) {
              this.cur2 = item.id;
              status = true
              this.currentName = item.name
            }else if(!status && item.id == sessionStorage.getItem('secondSelectId')) {
              this.cur2 = item.id;
              this.currentName = item.name
            }
          });
        }
      });
    },
    // 获取分类列表
    getCategory() {
      let params = {
        aid: this.aid,
        app_type: 1,
        pid: this.$route.query.id,
      };
      cateList(params).then((res) => {
        console.log('12232333------------------',res);
         if (res.code == 0) {
           var data = res.data.list;
           this.subTypeList = data;
           var childData = [];
           var status = false
           data.forEach((item) => {
             childData[item['id']] = item.children_data;
             
            if (item.name == this.$route.query.name) {

              this.cur2 = item.id;
              status = true
              this.currentName = item.name

            }else if(!status && item.id == sessionStorage.getItem('secondSelectId')) {
              this.cur2 = item.id;
              this.currentName = item.name
            }
          });
           this.allChildCat = childData;
           console.log(1233333,childData);
          this.getInfoList(1)

         }

      });
    },
    changeTab(id, i) {
      this.page = 1
      //this.cat_id = id;
      this.catID = this.cat_id;
      this.cur2 = i;
      if(id == -1) {
        this.getInfoList(-1);
        this.childTypeList = [];
      }else{
        this.cur2 = id
        this.getInfoList(id,true);
        this.childTypeList = this.allChildCat[id];

      }
    },
    changesenTab(id,i){
      this.page = 1
      if(i==-1){
        this.cat_id = this.catID;
      }

      this.seccur = i;
        console.log(id);
        this.getInfoList(id,true);

    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/news.scss";
.tab-secondNav-choose-item{
    font-size: 0.17rem;
    border-bottom: 1px solid #D5D5D5;
    padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
  // margin-left: 0.46rem;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
    width: 100%;
    height: 3px;
    display: inline-block;
    position: absolute;
    bottom: -0.14rem;
    left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
    background: #991C10;
    height: 3px;
    display: inline-block;
    position: absolute;
    animation: 0px;
    margin: auto;
    left: 0px;
    right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container{
  padding-top: 0.38rem;
}
</style>
